import React, { useState, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-hot-toast";
import ReactGA from "react-ga4";

function ArchiveModal(props) {
    const [show, setShow] = useState(false);
    const [isPosting, setIsPosting] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    const handleClose = () => setShow(false);

    const openModal = () => {
        setShow(true);
    };

    const handleSubmit = () => {
        setIsPosting(true);
        lockRelatedRooms();

        let payload = {
            archived : true,
        };
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            fetch(
                process.env.REACT_APP_MM_API_URL +
                    props.endpoint +
                    "/" +
                    props.object.id,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Success", data);
                    setShow(false);
                    props.refresh();
                    setIsPosting(false);
                    toast.success("Changes were saved.");
                })
                .catch((error) => {
                    toast.error("Something went wrong while saving.");
                    console.error(error);
                    setIsPosting(false);
                });
        });
    };

    const kickUsersFromRooms = useCallback(() => {
        console.log("CHeck for socket", props.socket);
        console.log(props.object.classroom_set);

        if (props.socket) {
            // For each room, join and send an update message
            props.object.classroom_set?.forEach((room) => {
                let ch = props.socket
                    .channel("hub:" + room.hub_id, {
                        profile: {
                            displayName: "teacher_bot_2df",
                            avatarId: "",
                        },
                        context: {
                            mobile: false,
                            hmd: false,
                            discord: true,
                        },
                    })
                    .join()
                    .receive("ok", (e) => {
                        console.log("joined channel", e);
                        const payload = {
                            type: "lockedRoom",
                        };

                        ch.channel.push("message", payload);
                    })
                    .receive("error", (e) => {
                        console.error("Could not connect to socket.channel", e);
                    });
            });
        }
    }, [props.socket]);

    const lockRelatedRooms = () => {

        kickUsersFromRooms();
        let payload = {
            locked: true,
        };

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            fetch(
                process.env.REACT_APP_MM_API_URL +
                    "classrooms/" +
                    props.object.related_room.hub_id +
                    "/",
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Success", data);
                })
                .catch((error) => {
                    console.error(error);
                });
        });
    };

    return (
        <>
            <Button
                variant="white"
                className="rounded-0"
                as={Dropdown.Item}
                onClick={() => openModal()}
            >
                {props.buttonText}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Archive Assignment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to archive <span className="fw-bold">{props.title}</span>?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Discard
                    </Button>
                    <Button
                        variant="success"
                        disabled={isPosting ? true : false}
                        onClick={handleSubmit}
                    >
                        {isPosting ? (
                            <>
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                <span>Loading...</span>
                            </>
                        ) : (
                            <span>Save changes</span>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ArchiveModal;
