import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

export default function TeacherProfile(props) {
    const [statusText, setStatusText] = useState("Loading ...");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [schoolOrDistrict, setSchoolOrDistrict] = useState("");
    const [heardAbout, setHeardAbout] = useState("");
    const [gradeLevel, setGradeLevel] = useState("");
    const [subjects, setSubjects] = useState("");
    const [isPosting, setIsPosting] = useState("");

    const handleSubmit = (e) => {
        setStatusText("Creating profile ...");
        e.preventDefault();

        setIsPosting(true);

        let payload = {
            first_name: firstName,
            last_name: lastName,
            school_or_district: schoolOrDistrict,
            where_heard: heardAbout,
            what_grades: gradeLevel,
            what_subjects: subjects,
            email: props.user.email,
            variant: "teacher",
        };

        props
            .getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                scope: "create:teacher_profile",
            })
            .then((token) => {
                fetch(process.env.REACT_APP_MM_API_URL + "teacherprofile/", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        // Generate new classroom pack here
                        setIsPosting(false);
                        props.onComplete();
                    })
                    .catch((error) => {
                        console.error(e);
                    });
            });
    };

    return (
        <Container className="mt-5" style={{ maxWidth: "642px" }}>
            <Alert variant="success">
                🎉 You have signed in successfully. Please fill out the fields below to
                continue.
            </Alert>
            <Card className="mt-4 mb-4">
                <Row className="p-3">
                    <Col>
                        <Form
                            onSubmit={(e) => handleSubmit(e)}
                            className="mt-2"
                        >
                            <Row className="mb-3">
                                <Form.Group
                                    as={Col}
                                    controlId="formGridFirstName"
                                >
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        required
                                        maxLength={500}
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                    />
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    controlId="formGridFirstName"
                                >
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        required
                                        maxLength={500}
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group
                                    as={Col}
                                    controlId="formGridFirstName"
                                >
                                    <Form.Label>
                                        Associated school or district
                                    </Form.Label>
                                    <Form.Control
                                        onChange={(e) =>
                                            setSchoolOrDistrict(e.target.value)
                                        }
                                        maxLength={500}
                                        placeholder=""
                                        type="text"
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group
                                    as={Col}
                                    controlId="formGridFirstName"
                                >
                                    <Form.Label>
                                        What grade level do you teach?
                                    </Form.Label>
                                    <Form.Control
                                        onChange={(e) =>
                                            setGradeLevel(e.target.value)
                                        }
                                        placeholder=""
                                        maxLength={500}
                                        type="text"
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group
                                    as={Col}
                                    controlId="formGridFirstName"
                                >
                                    <Form.Label>
                                        What subjects do you teach?
                                    </Form.Label>
                                    <Form.Control
                                        onChange={(e) =>
                                            setSubjects(e.target.value)
                                        }
                                        placeholder=""
                                        maxLength={500}
                                        type="text"
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group
                                    as={Col}
                                    controlId="formGridFirstName"
                                >
                                    <Form.Label>
                                        How did you hear about MegaMinds?
                                    </Form.Label>
                                    <Form.Control
                                        onChange={(e) =>
                                            setHeardAbout(e.target.value)
                                        }
                                        placeholder=""
                                        maxLength={500}
                                        type="text"
                                    />
                                </Form.Group>
                            </Row>
                            <div className="d-grid g-2">
                                <Button
                                    variant="success"
                                    type="submit"
                                    disabled={isPosting ? true : false}
                                >
                                    {isPosting ? (
                                        <>
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            <span className="ms-2">
                                                {statusText}
                                            </span>
                                        </>
                                    ) : (
                                        <span>Continue</span>
                                    )}
                                </Button>
                                <p className="mt-2">
                                    You will be redirected to the dashboard.
                                </p>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </Container>
    );
}
