import React from "react";
import { useParams } from "react-router-dom";

import Students from "./assessment/Students";
import Questions from "./assessment/Questions";
import ListGroup from "react-bootstrap/ListGroup";

import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import useDashApi from "../../services/useDashApi";
import { useAuth } from "../../context/AuthContext";

const EmptyArchivePage = (props) => {
    if (props.isLoadingArchived) {
        return null;
    }

    return (
            <div className="h-100 w-100 bg-light p-5">
            <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                <div style={{ maxWidth: "380px" }} className="text-center">
                    <i
                            className="bi bi-archive text-purple-dark"
                            style={{ fontSize: "3rem" }}
                        ></i>
                    <p className="fw-bold">              
                        Archived lessons will be accessible only to you. You can restore them anytime.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EmptyArchivePage;
