import React from "react";

const EmptyArchivedAssignmentPage = (props) => {
    if (props.isLoadingArchived) {
        return null;
    }

    return (
            <div className="h-100 w-100 bg-light p-5">
            <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                <div style={{ maxWidth: "380px" }} className="text-center">
                    <i
                        className="bi bi-archive text-purple-dark"
                        style={{ fontSize: "3rem" }}
                    ></i>
                    <p className="fw-bold">              
                        Archived assignments and their associated Student Spaces will be accessible only to you. You can restore them anytime.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EmptyArchivedAssignmentPage;
