import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const useSharedOrganizations = (auth) => {
    const { getAccessTokenSilently } = useAuth0();
    const [org, setOrg] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refreshIndex, setRefreshIndex] = useState(0);

    const refresh = () => {
        console.log("GOT REFRESH");
        setRefreshIndex(refreshIndex + 1);
    };

    useEffect(() => {
        if (!auth.isLoading && !auth.isProfileLoading && auth.profile) {
            (async () => {
                try {
                    const token = await getAccessTokenSilently({
                        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                    });
                    const response = await fetch(
                        process.env.REACT_APP_MM_API_URL + "organizations/" + "shared",
                        {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        }
                    );
                    setOrg(await response.json());
                    setLoading(false);
                } catch (e) {
                    console.error(e);
                    setLoading(false);
                    setError(e);
                }
            })();
        } else {
            setLoading(false);
            setError("You must be logged in to view this page.");
        }
    }, [auth, getAccessTokenSilently, refreshIndex]);

    return [org, loading, error, refresh];
};

export default useSharedOrganizations;
