import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import InputGroup from "react-bootstrap/InputGroup";
import Paginator from "../components/Paginator";

import ClassRoomCard from "./rooms/cards/ClassRoomCard";
import AssignmentsHeader from "./assignments/AssignmentsHeader";
import AssignmentsContent from "./assignments/AssignmentsContent";
import RestrictedClassRoomCard from "./rooms/cards/RestrictedClassRoomCard";
import EmptyAssignmentsPage from "./assignments/EmptyAssignmentsPage";
import InviteModal from "./assignments/modals/InviteModal";
import AssignmentListModal from "./assignments/modals/AssignmentListModal";
import ArchivedListModal from "./assignments/modals/ArchivedListModal";
import SharedListModal from "./rooms/modals/SharedListModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";

import { Helmet } from "react-helmet";
import useAssignmentAPI from "../services/useAssignmentAPI";
import useSingleAssignmentAPI from "../services/useSingleAssignmentAPI";
import useSharedAssignmentAPI from "../services/useSharedAssignmentAPI";
import useArchivedAssignmentAPI from "../services/useArchivedAssignmentAPI";
import useShowcasedAssignmentAPI from "../services/useShowcasedAssignmentAPI";

import { useAuth } from "../context/AuthContext";

const Assignments = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();

    const [newRoom, setNewRoom] = useState();

    useEffect(() => {
        if (location.state?.newRoom) {
            setNewRoom(location.state?.newRoom);
            console.log("Setting new room", location.state?.newRoom);
        }
    }, [location.state]);

    const [showInviteModalState, setShowInviteModalState] = useState(false);
    const [showAssignmentModalState, setShowAssignmentModalState] = useState(false);
    const [showArchivedModalState, setShowArchivedModalState] = useState(false);
    const [showSharedModalState, setShowSharedModalState] = useState(false);

    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState("-id");

    const [selectedId, setSelectedId] = useState(
        location.state?.selectedClassroom
    );

    const [
        selectedAssignment,
        selectedAssignmentLoading,
        selectedAssignmentError,
        refreshSelectedAssignment,
    ] = useSingleAssignmentAPI(selectedId);

    const [
        assignments,
        isLoadingAssignments,
        assignmentsError,
        refreshAssignments,
    ] = useAssignmentAPI(page, searchValue, sortValue, auth);

    const [shared_rooms, isLoadingShared, sharedError, refreshShared] =
    useSharedAssignmentAPI(page, searchValue, sortValue, auth);

    const [archived_rooms, isLoadingArchived, archivedError, refreshArchived] =
        useArchivedAssignmentAPI(page, searchValue, sortValue, auth);

    const [showcased_rooms, isLoadingShowcased, showcasedError, refreshShowcased] =
        useShowcasedAssignmentAPI(page, searchValue, sortValue, auth);

    const refreshAllRooms = () => {
        refreshAssignments();
        refreshShared();
        refreshArchived();
        refreshShowcased();
    };

    useEffect(() => {
        if (location.state) {
            navigate(location.pathname, { replace: true });
        }
    }, [location.pathname, location.state, navigate]);

    const hasInvalidPlan =
        auth.profile?.subscription_plan === "Free Plan" ||
        auth.profile?.subscription_plan === "Action Required";

    const showInviteModal = (assignment) => {
        setSelectedId(assignment.id);
        setShowInviteModalState(true);
    };

    const showStudentRoomsModal = (assignment) => {
        setSelectedId(assignment.id);
        setShowAssignmentModalState(true);
    }

    const showSharedRoomsModal = (assignment) => {
        setSelectedId(assignment.id);
        setShowSharedModalState(true);
    }
    const showArchivedRoomsModal = (assignment) => {
        setSelectedId(assignment.id);
        setShowArchivedModalState(true);
    }

    const swapToInvites = () => {
        setShowAssignmentModalState(false);
        setShowInviteModalState(true);
    };

    return (
        <>
            <Helmet>
                <title>Student Projects | MegaMinds</title>
            </Helmet>
            <Container fluid className="g-0 bg-light">
                <InviteModal
                    show={showInviteModalState}
                    setShow={setShowInviteModalState}
                    assignment={selectedAssignment}
                    refresh={refreshAllRooms}
                />
                <AssignmentListModal
                    show={showAssignmentModalState}
                    setShow={setShowAssignmentModalState}
                    assignment={selectedAssignment}
                    refreshSelectedAssignment={refreshSelectedAssignment}
                    refresh={refreshAllRooms}
                    swapToInvites={swapToInvites}
                />
                <ArchivedListModal
                    show={showArchivedModalState}
                    setShow={setShowArchivedModalState}
                    assignment={selectedAssignment}
                    refreshSelectedAssignment={refreshSelectedAssignment}
                    refresh={refreshAllRooms}
                    swapToInvites={swapToInvites}
                />
                <SharedListModal
                    show={showSharedModalState}
                    setShow={setShowSharedModalState}
                    assignment={selectedAssignment}
                    refreshSelectedAssignment={refreshSelectedAssignment}
                    refresh={refreshAllRooms}
                    swapToInvites={swapToInvites}
                />
                <AssignmentsHeader refreshRooms={refreshAssignments} />
            </Container>

            <AssignmentsContent
                auth={auth}
                refreshRooms={refreshAssignments}
                rooms={assignments}
                newRoom={newRoom}
                roomError={assignmentsError}
                isLoadingRooms={isLoadingAssignments}
                showStudentRoomsModal={showStudentRoomsModal}
                showSharedRoomsModal={showSharedRoomsModal}
                showArchivedRoomsModal={showArchivedRoomsModal}
                showInviteModal={showInviteModal}
                setSearchValue={setSearchValue}
                setSortValue={setSortValue}
                sortValue={sortValue}
                searchValue={searchValue}
                shared_rooms={shared_rooms}
                isLoadingShared={isLoadingShared}
                sharedError={sharedError}
                refreshShared={refreshShared}
                archived_rooms={archived_rooms}
                isLoadingArchived={isLoadingArchived}
                archivedError={archivedError}
                refreshArchived={refreshArchived}
                showcased_rooms={showcased_rooms}
                isLoadingShowcased={isLoadingShowcased}
                showcasedError={showcasedError}
                refreshShowcased={refreshShowcased}
                refreshAllRooms={refreshAllRooms}
            />
        </>
    );
};

export default Assignments;
