import React from "react";

const EmptyShowcasedAssignmentPage = (props) => {
    if (props.isLoadingShowcased) {
        return null;
    }

    return (
            <div className="h-100 w-100 bg-light p-5">
            <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                <div style={{ maxWidth: "380px" }} className="text-center">
                    <i
                        className="bi bi-collection text-purple-dark"
                        style={{ fontSize: "3rem" }}
                    ></i>
                    <p className="fw-bold">              
                        Showcased assignments put all Student Spaces in View Mode. The spaces will be visible to everyone and can be shared freely.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EmptyShowcasedAssignmentPage;
